//==============================
// Work
//==============================

$('.work-item').each(function() {
  var thisItem = $(this);
  var imgSrc = thisItem.find('img').attr('src');
  thisItem.find('.work-item-img').css({ 'background-image' : 'url(' + imgSrc + ')' });
  thisItem.find('.work-item-desc').remove();
});
