
//==============================
// Header
//==============================

var header = $('.header');
var button = $('.header-nav-button');
var nav = $('.header-nav');
var overlay = $('.header-nav-overlay');

// button
button.click(function() {
  header.toggleClass('open');
  button.toggleClass('open');
  nav.toggleClass('open');
  overlay.toggleClass('open');
});

// overlay
overlay.click(function() {
  header.removeClass('open');
  button.removeClass('open');
  nav.removeClass('open');
  overlay.removeClass('open');
});

// scroll
function headerNotTop() {
  header.addClass('scroll');
}
function headerAtTop() {
  header.removeClass('scroll');
}
function headerScroll() {
  var scrollPos = $(window).scrollTop();
  if (scrollPos<=1) {
    headerAtTop();
  }
  else {
    headerNotTop();
  }
}

headerScroll();

$(window).scroll(function() {
  headerScroll();
});

$('.js-scroll-to-anchor').click(function() {
  header.removeClass('open');
  button.removeClass('open');
  nav.removeClass('open');
});

