//==============================
// Accordion
//==============================

$('.accordion-link button').click(function() {
  var link = $(this).parent();
  var target = link.next();
  var group = link.parent('.accordion-group');
  if (link.hasClass('open')) {
    target.removeClass('open').css({ 'height' : 0 } );
    link.removeClass('open');
  }
  else {
    group.find('.accordion-link').removeClass('open');
    group.find('.accordion-target').removeClass('open').css({ 'height' : 0 } );
    link.addClass('open');
    target.addClass('open').css({ 'height' : target.get(0).scrollHeight } );
  }
  return false;
});

if ($('.accordion-group').length) {
  $('.accordion-group').each(function() {
    var thisGroup = $(this);
    var firstItem = thisGroup.find('.accordion-link:first-child');
    var secondItem = thisGroup.find('.accordion-target:nth-child(2)');
    firstItem.addClass('open');
    secondItem.addClass('open').css({ 'height' : secondItem.get(0).scrollHeight } );
  });
}

$(window).resize(function() {
  $('.accordion-target').each(function() {
    var elm = $(this);
    if (elm.hasClass('open')) {
      elm.css({ 'height' : 0 } );
      setTimeout(function() {
        elm.css({ 'height' : elm.get(0).scrollHeight } );
      }, 50);
    }
  });
});
