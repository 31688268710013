//==============================
// In The Stu
//==============================

var stuItems = $('.stu .wp_rss_retriever_list');

stuItems.slick({
  autoplay: false,
  slidesToShow: 3,
  swipeToSlide: true,
  centerMode: true,
  centerPadding: '80px',
  dots: false,
  arrows: true,
  touchThreshold: 20,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
        arrows: false
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false
      }
    },
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        centerPadding: '30px'
      }
    }
  ]
});

$('.wp_rss_retriever_item').each(function() {
  var thisItem = $(this);
  var thisTitle = thisItem.find('.wp_rss_retriever_title');
  var thisURL = thisTitle.attr('href');
  
  thisTitle.replaceWith(function() {
    return $('<h3/>', {
      html: this.innerHTML
    });
  });

  thisItem.find('.wp_rss_retriever_item_wrapper').replaceWith(function() {
    return $('<a/>', {
      html: this.innerHTML
    });
  });

  thisItem.find('a').attr('href', thisURL).attr('target', '_blank').addClass('wp_rss_retriever_item_wrapper');

  thisItem.find('.wp_rss_retriever_container').html('<span class="text-button">Read More</span>');

  thisItem.show();
});











