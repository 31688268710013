//==============================
// Hero
//==============================

// $('.hero-arrow').click(function() {
//   var thisArrow = $(this);
//   var nextSectionPos = thisArrow.parent().next().offset().top;
//   var windowWidth = $(window).width();

//   if (windowWidth >= 768) {
//     $('html, body').animate({ scrollTop: nextSectionPos - 59 - 100}, 700);
//   }
//   else {
//     $('html, body').animate({ scrollTop: nextSectionPos - 59 - 70}, 700);
//   }
// });

var heroSlider = $('.hero-slider');

heroSlider.slick({
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 800,
  slidesToShow: 1,
  swipeToSlide: true,
  dots: true,
  arrows: true
});
