//==============================
// Datepicker
//==============================

var disabledDays = [
  // '11-15',
  // etc.
];

$('.datepicker input, input.datepicker').datepicker({
  inline: true,
  dateFormat: "MM d, yy",
  minDate: 1,
  altField: '#datepicker input'
});
